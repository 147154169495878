import { queryOptions, useMutation } from '@tanstack/react-query';
import { graphql } from 'src/gql';
import {
  SignatureRequestMutationVariables,
  SignedMutationVariables,
} from 'src/gql/graphql';
import { graphqlRequest, queryClient } from './_request';

const getPixel = graphql(/* GraphQL */ `
  query Query {
    getPixel
  }
`);

const signatureRequestDocument = graphql(/* GraphQL */ `
  mutation SignatureRequest($data: RegistrationInput!) {
    signatureRequest(data: $data) {
      signUrl
      state
    }
  }
`);

const signedDocument = graphql(/* GraphQL */ `
  mutation Signed($data: RegistrationInput!, $state: String!) {
    signed(data: $data, state: $state) {
      uploadCifParams
    }
  }
`);

export function getPixelQueryOptions() {
  return queryOptions({
    queryKey: ['getPixel'],
    staleTime: Infinity,
    queryFn: () => graphqlRequest({ document: getPixel }),
  });
}

export function useSignatureRequestMutation() {
  return useMutation({
    mutationFn: (variables: SignatureRequestMutationVariables) =>
      graphqlRequest({
        document: signatureRequestDocument,
        variables,
      }),
  });
}

export function useSignedMutation() {
  return useMutation({
    mutationFn: (variables: SignedMutationVariables) =>
      graphqlRequest({
        document: signedDocument,
        variables,
      }),
  });
}

export function invalidatePixelQueries() {
  return queryClient.invalidateQueries({
    queryKey: ['getPixel'],
  });
}
