import Box, { BoxProps } from '@mui/material/Box';

export default function CaseIcon(props: BoxProps) {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M3.383 11.334c-.216 1.47-.216 3.4-.216 6 0 5.027 0 7.542 1.562 9.104C6.291 28 8.805 28 13.833 28h5.334c5.028 0 7.542 0 9.104-1.563 1.562-1.56 1.562-4.076 1.562-9.104 0-2.6 0-4.527-.216-6-3.036 1.974-4.981 3.232-6.784 4.007V16a1 1 0 0 1-2 .023c-2.84.749-5.826.749-8.666 0a1 1 0 0 1-2-.023v-.66c-1.803-.776-3.747-2.033-6.784-4.006Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.608 3h-.027c-.152 0-.269 0-.381.007A3.667 3.667 0 0 0 11.02 5.3c-.057.151-.11.304-.16.457l-.005.016c-.138.364-.37.684-.672.927-.304.01-.594.023-.871.04-2.192.128-3.583.488-4.583 1.49a4 4 0 0 0-.789 1.117.98.98 0 0 1 .223.108c2.8 1.82 4.56 2.96 6.022 3.69a1 1 0 0 1 1.982.189v.61a14.99 14.99 0 0 0 8.666 0v-.61a1 1 0 0 1 1.983-.19c1.461-.73 3.221-1.87 6.021-3.69.07-.044.145-.08.223-.107a4.032 4.032 0 0 0-.79-1.118c-1-1-2.39-1.36-4.582-1.49a44.563 44.563 0 0 0-.824-.038 2.508 2.508 0 0 1-.753-1.032l-.004-.012c-.048-.142-.084-.254-.127-.358a3.666 3.666 0 0 0-3.18-2.292A6.43 6.43 0 0 0 18.42 3h-3.813.001Zm5.65 3.421-.007-.014-.007-.016-.005-.016-.008-.02-.014-.04-.004-.01-.002-.01-.003-.008a4.68 4.68 0 0 0-.084-.242 1.666 1.666 0 0 0-1.445-1.042A5.592 5.592 0 0 0 18.392 5h-3.784c-.19 0-.244 0-.287.003a1.667 1.667 0 0 0-1.445 1.042l-.004.01-.01.028a6.871 6.871 0 0 0-.103.306l-.003.008-.004.01-.003.01-.013.04-.008.02-.005.016-.007.016-.005.014-.002.001a3.918 3.918 0 0 1-.058.144l1.182-.001h6.528a3.974 3.974 0 0 1-.104-.246Z"
        clipRule="evenodd"
      />
    </Box>
  );
}
