import Logo from 'src/components/logo';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { Grid, Link, Stack, SxProps, Typography } from '@mui/material';
import { Outlet } from 'react-router';

type Props = {
  children?: React.ReactNode;
};

export default function AuthClassicLayout({ children }: Props) {
  useScrollToTop();

  const boxSx: SxProps = {
    minHeight: { xs: 'auto', lg: '100vh' },
    gap: { xs: 4, lg: 5 },
    py: { xs: 3, md: 5 },
    px: { xs: 2, md: 4, lg: 5 },
  };

  return (
    <Grid container direction={{ xs: 'column', lg: 'row' }}>
      <Grid item xs={6}>
        <Stack sx={{ ...boxSx, position: 'sticky', top: 0 }}>
          <Logo variant="standard" />

          <Stack
            sx={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Stack
              sx={{
                gap: { xs: 1, lg: 4 },
                maxWidth: { xs: 360, lg: 600 },
              }}
            >
              <Typography variant="h2" color="#00292C">
                Start working&nbsp;with the&nbsp;Affiliate&nbsp;Portal
              </Typography>
              <Typography variant="body1" fontSize={21}>
                For free for individuals or companies
              </Typography>
            </Stack>
          </Stack>

          <Stack sx={{ display: { xs: 'none', lg: 'flex' } }}>
            <Contacts />
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={6}>
        <Stack
          sx={{
            ...boxSx,
            background: {
              lg: 'radial-gradient(254.78% 81.57% at 104.36% 9.97%, rgba(0, 255, 242, 0.14) 0%, rgba(0, 255, 242, 0.00) 100%), #F4F6F8',
            },
            justifyContent: 'center',
          }}
        >
          {children ?? <Outlet />}
        </Stack>

        <Stack
          sx={{
            alignItems: 'center',
            py: 4,
            display: { xs: 'flex', lg: 'none' },
          }}
        >
          <Contacts />
        </Stack>
      </Grid>
    </Grid>
  );
}

function Contacts() {
  return (
    <Typography variant="caption">
      <Link href="mailto:support@reviero.com">support@reviero.com</Link>
    </Typography>
  );
}
