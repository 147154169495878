// scrollbar
// import 'simplebar-react/dist/simplebar.min.css';

// image
// import 'react-lazy-load-image-component/src/effects/blur.css';

import { QueryClientProvider } from '@tanstack/react-query';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AppRouter } from 'src/routes/sections';
import ThemeProvider from 'src/theme';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsDrawer } from 'src/components/settings';
import { AuthProvider } from './auth/context/auth0';
import { queryClient } from './services/_request';
import { SnackbarProvider } from './components/snackbar';

export default function App() {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SnackbarProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <MotionLazy>
                <SettingsDrawer />
                <AppRouter />
              </MotionLazy>
            </AuthProvider>
          </QueryClientProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
