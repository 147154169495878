// ----------------------------------------------------------------------

const ROOTS = {
  MAIN: '/',
  AUTH: '/auth',
  REGISTRATION: '/auth/registration',
  EMAIL_VERIFICATION: '/auth/email-verification',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: ROOTS.AUTH,
  registration: ROOTS.REGISTRATION,
  emailVerification: ROOTS.EMAIL_VERIFICATION,
  // DASHBOARD
  dashboard: {
    root: ROOTS.MAIN,
  },
};
