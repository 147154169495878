/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  query GetAnalyticsVisitors($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsVisitors(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n": types.GetAnalyticsVisitorsDocument,
    "\n  query GetAnalyticsDreamers($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsDreamers(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n": types.GetAnalyticsDreamersDocument,
    "\n  query GetAnalyticsMQL($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsMQL(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n": types.GetAnalyticsMqlDocument,
    "\n  query GetAnalyticsSQL($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsSQL(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n": types.GetAnalyticsSqlDocument,
    "\n  query GetAnalyticsRetentionRate($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsRetentionRate(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      extraValue\n      dateAt\n    }\n  }\n": types.GetAnalyticsRetentionRateDocument,
    "\n  query GetAnalyticsSessionLength($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsSessionLength(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n": types.GetAnalyticsSessionLengthDocument,
    "\n  query GetAnalyticsSessionInterval($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsSessionInterval(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n": types.GetAnalyticsSessionIntervalDocument,
    "\n  query GetAnalyticsPropertyPriceRange(\n    $startDate: DateTime\n    $endDate: DateTime\n  ) {\n    getAnalyticsPropertyPriceRange(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n": types.GetAnalyticsPropertyPriceRangeDocument,
    "\n  query GetAnalyticsPropertyProfitRange(\n    $startDate: DateTime\n    $endDate: DateTime\n  ) {\n    getAnalyticsPropertyProfitRange(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n": types.GetAnalyticsPropertyProfitRangeDocument,
    "\n  query GetAnalyticsPropertyTypes($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsPropertyTypes(startDate: $startDate, endDate: $endDate) {\n      dateAt\n      key\n      value\n    }\n  }\n": types.GetAnalyticsPropertyTypesDocument,
    "\n  query GetAnalyticsCountries($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsCountries(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n": types.GetAnalyticsCountriesDocument,
    "\n  query GetAnalyticsPlatforms($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsPlatforms(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n": types.GetAnalyticsPlatformsDocument,
    "\n  query Query {\n    getPixel\n  }\n": types.QueryDocument,
    "\n  mutation SignatureRequest($data: RegistrationInput!) {\n    signatureRequest(data: $data) {\n      signUrl\n      state\n    }\n  }\n": types.SignatureRequestDocument,
    "\n  mutation Signed($data: RegistrationInput!, $state: String!) {\n    signed(data: $data, state: $state) {\n      uploadCifParams\n    }\n  }\n": types.SignedDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAnalyticsVisitors($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsVisitors(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"): (typeof documents)["\n  query GetAnalyticsVisitors($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsVisitors(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAnalyticsDreamers($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsDreamers(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"): (typeof documents)["\n  query GetAnalyticsDreamers($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsDreamers(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAnalyticsMQL($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsMQL(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"): (typeof documents)["\n  query GetAnalyticsMQL($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsMQL(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAnalyticsSQL($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsSQL(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"): (typeof documents)["\n  query GetAnalyticsSQL($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsSQL(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAnalyticsRetentionRate($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsRetentionRate(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      extraValue\n      dateAt\n    }\n  }\n"): (typeof documents)["\n  query GetAnalyticsRetentionRate($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsRetentionRate(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      extraValue\n      dateAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAnalyticsSessionLength($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsSessionLength(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"): (typeof documents)["\n  query GetAnalyticsSessionLength($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsSessionLength(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAnalyticsSessionInterval($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsSessionInterval(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"): (typeof documents)["\n  query GetAnalyticsSessionInterval($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsSessionInterval(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAnalyticsPropertyPriceRange(\n    $startDate: DateTime\n    $endDate: DateTime\n  ) {\n    getAnalyticsPropertyPriceRange(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"): (typeof documents)["\n  query GetAnalyticsPropertyPriceRange(\n    $startDate: DateTime\n    $endDate: DateTime\n  ) {\n    getAnalyticsPropertyPriceRange(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAnalyticsPropertyProfitRange(\n    $startDate: DateTime\n    $endDate: DateTime\n  ) {\n    getAnalyticsPropertyProfitRange(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"): (typeof documents)["\n  query GetAnalyticsPropertyProfitRange(\n    $startDate: DateTime\n    $endDate: DateTime\n  ) {\n    getAnalyticsPropertyProfitRange(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAnalyticsPropertyTypes($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsPropertyTypes(startDate: $startDate, endDate: $endDate) {\n      dateAt\n      key\n      value\n    }\n  }\n"): (typeof documents)["\n  query GetAnalyticsPropertyTypes($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsPropertyTypes(startDate: $startDate, endDate: $endDate) {\n      dateAt\n      key\n      value\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAnalyticsCountries($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsCountries(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"): (typeof documents)["\n  query GetAnalyticsCountries($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsCountries(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAnalyticsPlatforms($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsPlatforms(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"): (typeof documents)["\n  query GetAnalyticsPlatforms($startDate: DateTime, $endDate: DateTime) {\n    getAnalyticsPlatforms(startDate: $startDate, endDate: $endDate) {\n      key\n      value\n      dateAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Query {\n    getPixel\n  }\n"): (typeof documents)["\n  query Query {\n    getPixel\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SignatureRequest($data: RegistrationInput!) {\n    signatureRequest(data: $data) {\n      signUrl\n      state\n    }\n  }\n"): (typeof documents)["\n  mutation SignatureRequest($data: RegistrationInput!) {\n    signatureRequest(data: $data) {\n      signUrl\n      state\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Signed($data: RegistrationInput!, $state: String!) {\n    signed(data: $data, state: $state) {\n      uploadCifParams\n    }\n  }\n"): (typeof documents)["\n  mutation Signed($data: RegistrationInput!, $state: String!) {\n    signed(data: $data, state: $state) {\n      uploadCifParams\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;