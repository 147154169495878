import { useCallback, useEffect, useRef } from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { SnackbarKey, useSnackbar } from 'src/components/snackbar';
import { accessToken } from './token';

type Props = {
  children: React.ReactNode;
};

export { accessToken };

// Set access token silently on mount and update it when it changes
function AccessTokenSilently() {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    accessToken.setAccessTokenSilently(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  return null;
}

function AuthNotification() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { error, isAuthenticated } = useAuth0();
  const snackbarRef = useRef<SnackbarKey | null>(null);

  // Handle snackbar close
  const handleSnackbarClose = useCallback(() => {
    if (snackbarRef.current) {
      closeSnackbar(snackbarRef.current);
    }
  }, [closeSnackbar]);

  // Show snackbar when authentication status changes
  useEffect(() => {
    // Show error message when authentication fails
    if (error && error?.message !== 'Popup closed') {
      snackbarRef.current = enqueueSnackbar({
        message: 'Authentication failed - please try again',
        variant: 'error',
        autoHideDuration: 3000,
      });

      // Log error to console
      console.error(error);
    }
    // Show success message when authentication succeeds
    else if (isAuthenticated) {
      snackbarRef.current = enqueueSnackbar({
        message: 'You are now signed in',
        variant: 'success',
        autoHideDuration: 3000,
      });
    }

    return handleSnackbarClose;
  }, [error, isAuthenticated, enqueueSnackbar, handleSnackbarClose]);

  return null;
}

export function AuthProvider({ children }: Props) {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AFFILIATE_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AFFILIATE_AUTH0_CLIENT_ID!}
      authorizationParams={{
        audience: `https://${process.env.REACT_APP_AFFILIATE_AUTH0_DOMAIN}/api/v2/`,
        scope: 'openid profile email',
        redirect_uri: window.location.origin,
      }}
    >
      <AccessTokenSilently />
      <AuthNotification />
      {children}
    </Auth0Provider>
  );
}
