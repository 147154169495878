import Box, { BoxProps } from '@mui/material/Box';

export default function UserIcon(props: BoxProps) {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16.5 13.333a5.333 5.333 0 1 0 0-10.666 5.333 5.333 0 0 0 0 10.666ZM16.5 28c5.155 0 9.333-2.388 9.333-5.333 0-2.946-4.178-5.334-9.333-5.334s-9.333 2.388-9.333 5.334C7.167 25.612 11.345 28 16.5 28Z"
      />
    </Box>
  );
}
