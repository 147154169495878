import { forwardRef } from 'react';
// @mui
import Link from '@mui/material/Link';
import { BoxProps } from '@mui/material/Box';
import { LogoIcon, MiniLogoIcon } from 'src/assets/icons';
// routes
import { RouterLink } from 'src/routes/components';
import { StandardLogoIcon } from 'src/assets/icons/logo-icon';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  variant?: 'mini' | 'standard' | 'default';
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, variant = 'default', ...other }, ref) => {
    let logo;

    if (variant === 'mini') {
      logo = <MiniLogoIcon sx={{ width: 40, height: 40, ...sx }} />;
    } else if (variant === 'standard') {
      logo = (
        <StandardLogoIcon
          sx={{
            width: 140,
            height: 40,
            ...sx,
          }}
        />
      );
    } else {
      logo = (
        <LogoIcon
          sx={{
            width: 140,
            height: 40,
            ...sx,
          }}
        />
      );
    }

    if (disabledLink) {
      return logo;
    }

    return (
      <Link
        component={RouterLink}
        href="/"
        sx={{ display: 'contents' }}
        {...other}
      >
        {logo}
      </Link>
    );
  }
);

export default Logo;
