import { Navigate } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { paths } from 'src/routes/paths';
import { getPixelQueryOptions } from 'src/services/registration';
import { useQuery } from '@tanstack/react-query';
import { usePathname } from 'src/routes/hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const pathname = usePathname();
  const auth = useAuth0();
  const pixelQuery = useQuery({
    ...getPixelQueryOptions(),
    enabled: auth.isAuthenticated && !!auth.user?.email_verified,
    retry: false,
  });

  // Determine if the user is accepted
  let isAccepted = true;

  // If the user is not authenticated, redirect to the login page
  if (!auth.isAuthenticated) {
    isAccepted = false;

    if (pathname !== paths.auth) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname + window.location.search,
      });
      return <Navigate to={`${paths.auth}?${searchParams}`} replace />;
    }
  }

  // If the user is not verified, redirect to the email verification page
  if (!auth.user?.email_verified) {
    isAccepted = false;

    if (pathname !== paths.emailVerification) {
      return <Navigate to={paths.emailVerification} replace />;
    }
  }

  // If the registration query is still loading
  if (pixelQuery.isLoading) {
    return null;
  }

  // If the user is not fully registered, redirect to the registration page
  if (!pixelQuery.data?.getPixel) {
    isAccepted = false;

    if (pathname !== paths.registration) {
      return <Navigate to={paths.registration} replace />;
    }
  }

  // If the user is authenticated and on the login, email verification, or registration page, redirect to the dashboard
  if (
    isAccepted &&
    [paths.auth, paths.emailVerification, paths.registration].includes(pathname)
  ) {
    return <Navigate to={paths.dashboard.root} replace />;
  }

  return <>{children}</>;
}
